import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useStaticQuery, graphql } from "gatsby";

type TProps = {
  description?: string;
  keywords?: string;
  lang?: string;
  meta?: Array<any>;
  title: string;
  type?: string;
  ogImage?: string | null;
  ogUrl?: string;
};

const Seo = ({
  description = "",
  keywords = "",
  lang = "en",
  meta = [],
  title,
  type = "website",
  ogImage,
  ogUrl,
}: TProps): JSX.Element => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const ogImageUrl = `${ogImage}?w=1200&fit=max&auto=format`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: "twitter:title",
          conntent: title,
        },
        {
          property: "twitter:description",
          conntent: metaDescription,
        },

        ...(ogUrl
          ? [
              {
                property: `og:url`,
                content: ogUrl,
              },
            ]
          : []),

        ...(ogImage
          ? [
              {
                property: `og:image`,
                content: ogImageUrl,
              },
              {
                property: `og:image:alt`,
                content: "Hero",
              },
              {
                property: "twitter:image",
                conntent: ogImageUrl,
              },
              {
                name: "twitter:card",
                conntent: "summary_large_image",
              },
            ]
          : []),
      ].concat(meta)}
    />
  );
};

export default Seo;

export const query = graphql`
  fragment SeoOgImage on SanitySeoImage {
    image {
      asset {
        url
      }
    }
  }
`;
