import React from "react";
import { graphql, PageProps } from "gatsby";
import { Box } from "grommet";
import { PortableText } from "@portabletext/react";
import type { PortableTextBlock } from "@portabletext/types";

import Section from "@components/Section";
import Seo from "@components/Seo";
import { defaultComponents } from "@utils/PortableText";

type TPrivacyQuery = Queries.PrivacyQuery & {
  site: {
    _rawPrivacy?: PortableTextBlock[];
  };
};

const PrivacyPage = ({
  data: { site },
  location,
}: PageProps<TPrivacyQuery>) => {
  return (
    <Section constrained="hd" background="grey-3">
      <Seo title="Privacy Policy" ogUrl={location?.href} />

      <Box pad={{ vertical: "large" }} align="center">
        <Box width={{ max: "730px" }}>
          <PortableText
            value={site?._rawPrivacy}
            components={defaultComponents({ hLevel: 2 })}
          />
        </Box>
      </Box>
    </Section>
  );
};

export default PrivacyPage;

export const query = graphql`
  query Privacy {
    site: sanitySite {
      _id
      id

      _rawPrivacy
    }
  }
`;
